import * as React from "react"
import headingImage from "../images/bazel.svg"
import {
  PageHeading,
  PageHeadingParagraph,
  PageWrapper,
} from "../components/modern/layout"
import {
  ContentParagraph,
  List,
  ListItem,
  TitleStripe,
} from "../components/modern/content"

const BazelPage = () => {
  return (
    <PageWrapper title="Bazel" pathname="/bazel">
      <PageHeading
        title="Optimize your Bazel implementation"
        imageSrc={headingImage}
      >
        <PageHeadingParagraph>
          Tweag is the software innovation lab driving Bazel’s worldwide
          adoption and ongoing development. As Google’s first Bazel Community
          Expert, we can help you optimize every phase of your Bazel
          implementation, from preparing for migration to improving your
          existing setup.
        </PageHeadingParagraph>
        <PageHeadingParagraph>
          We can answer your toughest Bazel questions. Combining rich Bazel
          skills with deep technology expertise, Tweag offers rare insight into
          Bazel&rsquo;s capabilities (and challenges) across diverse
          environments.
        </PageHeadingParagraph>
      </PageHeading>
      <TitleStripe title="Ready" bgColor="red">
        <ContentParagraph>
          Get a head start on your Bazel migration with Tweag’s Readiness
          Assessment. We&rsquo;ll document expected improvements, potential
          challenges and required resources to help you plan for a successful
          migration to a Bazel build system.
        </ContentParagraph>
      </TitleStripe>
      <TitleStripe title="Tune" bgColor="orange">
        <ContentParagraph>
          Harness Bazel&rsquo;s power after migration. We&rsquo;ll help you tune
          your Bazel to enjoy dramatic improvements in productivity and
          performance. From improving cache hits to writing custom rules, we
          have experience adapting Bazel to your needs. We&rsquo;ve also been
          successful advocates for getting solutions to our client&rsquo;s
          use-case requirements incorporated into core Bazel.
        </ContentParagraph>
      </TitleStripe>
      <TitleStripe title="Result" bgColor="grey">
        <ContentParagraph>
          We have worked with clients across a range of industries:
        </ContentParagraph>
        <List>
          <ListItem>In silico simulations of human physiology</ListItem>
          <ListItem>
            Novel static analysis tools for the autopilot software in autonomous
            taxis
          </ListItem>
          <ListItem>Designing smart contract languages</ListItem>
          <ListItem>
            Improving the performance of third generation blockchains
          </ListItem>
        </List>
      </TitleStripe>
    </PageWrapper>
  )
}

export default BazelPage
