import * as React from "react"
import { Box, Flex, Text, Paragraph, Heading } from "theme-ui"
import { PaddedContent } from "./layout"

// The paragraph component to use for regular text content
export const ContentParagraph: React.FC<{
  children: React.ReactNode
}> = ({ children }) => (
  <Paragraph
    sx={{
      marginY: `1em`,
    }}
  >
    {children}
  </Paragraph>
)

// A list wrapper that should contain `ListItem` elements
export const List = ({ children }) => {
  return (
    <Flex
      sx={{
        flexDirection: `column`,
        marginY: `1em`,
        gap: `1em`,
      }}
    >
      {children}
    </Flex>
  )
}

// A list item prefixed by an arrow bullet
export const ListItem = ({ children }) => {
  return (
    <Flex
      sx={{
        gap: `1em`,
      }}
    >
      <Text>→</Text>
      <>{children}</>
    </Flex>
  )
}

// A colored stripe with an impactful word or title on the left
export const TitleStripe = ({ title, bgColor, children }) => {
  return (
    <PaddedContent background={bgColor}>
      <Flex
        sx={{
          marginY: `3em`,
          flexWrap: `wrap`,
          maxWidth: `58em`,
          columnGap: `2em`,
          rowGap: `1em`,
          marginX: `auto`,
          fontSize: [`1.2em`, `1.5em`],
        }}
      >
        <Heading
          sx={{
            flexBasis: `8em`,
            fontSize: `2em`,
            textTransform: `uppercase`,
          }}
        >
          {title}
        </Heading>
        <Box
          sx={{
            flexBasis: `40em`,
          }}
        >
          {children}
        </Box>
      </Flex>
    </PaddedContent>
  )
}

export const Quote = ({ author, quote }) => {
  return (
    <Box
      sx={{
        maxWidth: `25em`,
      }}
    >
      <Paragraph
        sx={{
          fontSize: `2em`,
          fontStyle: `italic`,
          fontWeight: `bold`,
          marginBottom: `1em`,
        }}
      >
        “{quote}”
      </Paragraph>
      <Paragraph
        sx={{
          fontSize: `1.2em`,
        }}
      >
        {author}
      </Paragraph>
    </Box>
  )
}
